<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:" @click="router.go(-1)" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div v-if="fileItems.length > 0" class="header-column header-util right">
        <div class="util util-download">
          <a href="javascript:" @click="isFileAtchListShow = true" class="util-actions util-actions-download">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container helpdesk">
        <div class="view-header">
          <div class="title-wrap">
            <h3 class="title">{{items.title}} </h3>
            <p class="subtitle"><span class="text">공지</span><span class="text">{{ getDateFormat('yyyy.MM.dd hh:mm', items.regDt) }} 등록</span>
              <span class="text">{{items.inqCnt}} 조회</span></p>
          </div>
        </div>
        <div class="view-body">
          <div class="view-content" v-html="items.cn"/>
        </div>
      </div>

    </div>
  </main>
  <!-- end::kb-main -->

  <!-- //main-content -->
  <MobileLiveNoticeFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :fileItems="fileItems"/>

</template>

<script>

import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {getItem, getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {
  ACT_DOWNLOAD_BOARD_ATCH,
  ACT_GET_LRN_BOARD_FILE_ATCH_LIST
} from "@/store/modules/board/board";
import {convertToStorageBytes} from "@/assets/js/util";
import MobileLiveNoticeFileAtch from "@/views/pages/live/mobile/MobileLiveNoticeFileAtch";
import {ACT_BOARD_GET_NTC} from "@/store/modules/live/live";

export default {
  name: 'MobileLiveNoticeView',
  components: {MobileLiveNoticeFileAtch},
  setup(){
    const noticeTitle = ref('연수시스템 점검 안내');
    const items = ref({});
    const fileItems = ref([]);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const showAttachments = ref(false);

    const lrnPostSn = computed(() => route.params.lrnPostSn);

    const isFileAtchListShow = ref(false);

    const getLrnBoardNotice = () => {
      store.dispatch(`live/${ACT_BOARD_GET_NTC}`,
          lrnPostSn.value,
      ).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
          getLrnBoardNoticeFileAtchList();
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getLrnBoardNotice();

    const getLrnBoardNoticeFileAtchList = () => {
      store.dispatch(`board/${ACT_GET_LRN_BOARD_FILE_ATCH_LIST}`, {
        lrnPostSn: lrnPostSn.value,
        stt: '00'
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          fileItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const pageViewCnt = async () => {
      if (lrnPostSn.value > 0) {
        console.log()
        // await store.dispatch(`help/${ACT_UPDATE_NOTICE_CNT}`, {lrnPostSn: lrnPostSn.value});
      }
    };

    const downloadAtch = (atch) => {
      store.dispatch(`board/${ACT_DOWNLOAD_BOARD_ATCH}`, { atchFileSn: atch.atchFileSn, fileName: atch.fileNm });
    };

    onMounted(() => {
      pageViewCnt();
    });

    watch(() => route.params.ntcSn, ()=>{
      getLrnBoardNotice();
    });

    return {
      noticeTitle,
      items,
      fileItems,
      showAttachments,
      isFileAtchListShow,
      router,
      getDateFormat,
      downloadAtch,
      convertToStorageBytes,
    }

  }
}
</script>
<style type="text/css">
.view-content p{
  font: unset;
}
</style>